/*dev*/
.t {
  border: 1px solid red;
}

.iti{
  width: 100% !important;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.p5Canvas{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: -1;
}

$bg: #fff;
$white: #fff;
$text: #7288a2;
$gray: #4d5974;
$lightgray: #e5e5e5;
$blue: #007bff;
$shadow-color: #0000002D;

.scroll {
  overflow: scroll;

  &-x {
    overflow-x: scroll;
  }

  &-y {
    overflow-y: scroll;
  }
}

.gap {
  &-1 {
    gap: 10px;
  }

  &-2 {
    gap: 20px;
  }

  &-3 {
    gap: 30px;
  }

  &-4 {
    gap: 40px;
  }

  &-5 {
    gap: 50px;
  }

  &-y {
    &-1 {
      row-gap: 10px;
    }

    &-2 {
      row-gap: 20px;
    }

    &-3 {
      row-gap: 30px;
    }

    &-4 {
      row-gap: 40px;
    }

    &-5 {
      row-gap: 50px;
    }
  }

  &-x {
    &-1 {
      column-gap: 10px;
    }

    &-2 {
      column-gap: 20px;
    }

    &-3 {
      column-gap: 30px;
    }

    &-4 {
      column-gap: 40px;
    }

    &-5 {
      column-gap: 50px;
    }
  }
}

.shadow {
  &-sm {
    box-shadow: 0 0 0 3px $shadow-color;
  }

  &-md {
    box-shadow: 0 0 2px 5px $shadow-color;
  }

  &-lg {
    box-shadow: 0 0 5px 5px $shadow-color;
  }

  &-xl {
    box-shadow: 0 0 8px 10px $shadow-color;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.bg {
  &--auth {
    background-image: url("https://nuxtjs.org/img/home/home_footer.svg");
    background-repeat: no-repeat;
    //background-size: cover;
    //background-position: center;
    background-size: 100%;
    background-position-y: bottom;
    background-position-x: center;
  }
}

.root-container {
  display: flex;
  flex-direction: column;

  &--full {
    height: 100vh;
  }

  &__item {
    height: 100%;

    &--shrink {
      flex-shrink: 0;
    }
  }
}

// auto form
.intl-tel-input {
  display: table-cell;
  width: 100%;
}

.intl-tel-input .selected-flag {
  z-index: 4;
}

.intl-tel-input .country-list {
  z-index: 5;
}

.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}

.avatar {
  &__round {
    &__sm {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%
    }

    &__md {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%
    }

    &__lg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%
    }

    &__xl {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%
    }
  }
}

* {
  box-sizing: border-box;

  &::before, &::after {
    box-sizing: border-box;
  }
}

.accordion {
  .accordion-item {
    border-bottom: 1px solid $lightgray;

    button[aria-expanded='true'] {
      border-bottom: 1px solid #333;
    }
  }

  button {
    outline: none;
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $text;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;

    &:hover, &:focus {
      cursor: pointer;
      color: #333;
      outline: 0px auto -webkit-focus-ring-color;

      &::after {
        cursor: pointer;
        color: #333;
        border: 1px solid #333;
      }
    }

    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }

    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;

      &::before {
        display: block;
        position: absolute;
        content: '';
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }

      &::after {
        display: block;
        position: absolute;
        content: '';
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }

  button[aria-expanded='true'] {
    color: #333;

    .icon {
      &::after {
        width: 0;
      }
    }

    + .accordion-content {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }

  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;

    p {
      font-size: 1rem;
      font-weight: 300;
      margin: 2em 0;
    }
  }
}

.customer_review {
  height: 400px;

  &__item {
    //background-color: deepskyblue;
    height: 300px;
    position: relative;
    margin: 0 5px;
    height: 100%;
    width: 100%;

    &__icon {
      width: 40px;
      height: 40px;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      opacity: .3;
      right: 20px;
      top: 20px;
      z-index: 50;
    }

    p {
      font-size: 1.2rem;
      color: $gray;
    }
  }
}

.img__fluid {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.cus-rounded-lg {
  border-radius: 10px;
}
.cus-rounded-xl {
  border-radius: 20px;
}

/*brand*/
.brand {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand__item {
    &--text {
    }

    &--logo {
      width: 160px;
    }
  }
}

/*header*/
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 30px;
  //background-color: rgba(255, 255, 255, 1);
  transition: 0.3s ease-in-out;
}

.scroll-bg {
  background-color: rgba(255, 255, 255, 1) !important;
}


/*navbar*/
.cus-nav {
  display: flex;
  z-index: 100;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
      padding: 0;
      margin: 0;
    }
  }

  &__item {
    a {
      padding: 15px;
      color: $gray;
      text-transform: capitalize;
      display: flex;
      text-decoration: none;
      transition: .3s ease-in-out;
      //background-color: #0a3544;
    }
    &--active {
      a{
        text-decoration: none;
        color: $blue;
      }
    }

    a:hover {
      text-decoration: none;
      color: $blue;
    }

    &--more {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &--more:hover {
      ul {
        //animation: fade_in .3s ease-in-out forwards;
        animation: slide-in .3s ease-in-out forwards;
        display: flex;
        border-top: 3px solid $blue;
        padding: 10px 0;
      }

      a:hover {
        ul {
          display: flex;
        }
      }

      & > a {
        color: $blue;
      }
    }


    ul {
      list-style: none;
      position: absolute;
      top: 55px;
      display: none;
      flex-direction: column;
      background-color: $white;
      box-shadow: 0 1px 3px $lightgray;
      right: 0;
      width: 200px;
      border: 1px solid #f1f1f1;

      h6 {
        /*font-weight: bolder;*/
        padding: 5px 20px;
      }

      li {
        width: 100%;

        a {
          width: 100%;
          padding: 5px 20px;
          font-size: smaller;
        }

        a:hover {
          background-color: #007bff10;
        }
      }
    }
  }

  &__title {
    height: 60px;
    padding: 10px 20px;

    h5 {
      color: $white;
    }

    .close {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      //background-color: #ffffff90;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.hidden {
  display: none;
}

.menu {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .menu-icon {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    span {
      width: 20px;
      height: 3px;
      background-color: #666;
    }

    span:first-child {
      width: 25px;
    }

    span:last-child {
      width: 15px;
    }
  }
}

.main_root {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 150px;
}

.container__root {
  gap: 30px;
}

.customer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100px;
      width: 100%;
      height: 100px;
    }
  }
}

.features {
  &__item {
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition: .3s ease-in-out;

    .icon {
      width: 96px;
    }

    .title {
      font-weight: bold;
      font-size: 1.3rem;
    }

    .content {
      font-size: 1rem;
    }
  }

  &__item:hover {
    //box-shadow: 0 0 20px 1px $lightgray;
  }
}

.deliverability {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $white;
    }

    .title {
      font-size: 1.3rem;
      font-weight: bold;
    }

    .content {
      font-size: .9rem;
    }
  }
}

//plans
.plans {
  display: flex;
  box-shadow: 0 0 10px 1px $lightgray;
  transition: .3s ease-in-out;
  padding: 30px !important;

  &__item {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__feature {
      line-height: 80%;
      &__item {
        display: flex;
        align-items: center;
      }
    }
    &__tag {
      position: absolute;
      top: 0;
      width: 160px;
      right: 0;
      height: 160px;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      align-items: center;
      & .tag {
        text-align: center;
        font-size: x-small;
        background-color: #e88a02;
        color: #fff;
        transform: rotateZ(35deg);
        width: 200px;
        padding: 5px;
        margin-right: -30px;
        margin-top: -70px;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 25px 1px $lightgray;
  }
  & .btn-rounded {
    border-radius: 50px;
  }
}

.strikethrough {
  position: relative;
  color: #999 !important;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: #666;
    
    -webkit-transform:rotate(15deg);
    -moz-transform:rotate(15deg);
    -ms-transform:rotate(15deg);
    -o-transform:rotate(15deg);
    transform:rotate(15deg);
  }
}

.footer__item {
  list-style: none;
  margin: 0;
  padding: 0;

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  li {
    padding: 5px 0;

    a {
      color: $gray;
    }
  }
}

// form
.form_root {
  max-width: 660px;
  width: 100%;
  //border-top: 3px solid $blue;
  //background-color: #fff;
  //box-shadow: 0 0 15px 1px $lightgray;
  //border-radius: 10px;
  padding: 30px;
  margin: 0 auto;
  &--modified {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 30px;
  }
  .icon {
    width: 180px;
  }
  h3{
    font-size: 24px;
  }
  label {
    font-size: 15px;
  }
  small{
    font-size: 13.98px;
  }
  .link {
    &--muted {
      color: $gray;
      text-decoration: underline !important;
    }
    &--dark {
      color: #000;
      text-decoration: underline !important;
    }
  }
}

// auth layout
.auth {
  display: flex;
  &__item {
    width: 100%;
    &--bg {
      max-width: 900px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }
}

// loader
.loader{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
}
.loader__item{
  img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 992px) {
  header {
    padding: 10px;
    transition: .3s ease-in-out;
  }
  .main_root {
    margin-top: 100px;
  }
  .bg {
    &--auth {
      background-image: url("https://nuxtjs.org/img/home/home_footer.svg");
      background-repeat: no-repeat;
      background-size: cover;
      //background-position: center;
      //background-size: 100%;
      background-position-y: bottom;
      background-position-x: center;
    }
  }
  .brand {
    .brand__item {
      &--text {
      }

      &--logo {
        width: 100px;
      }
    }
  }
  .cus-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray;
    z-index: 1000;
    animation: slide-in .3s ease-in-out forwards;

    ul {
      width: 100%;
      flex-direction: column;
      overflow-y: scroll;

      li {
        width: 100%;

        a {
          color: $white;
          background-color: #ffffff20;
          margin: 1px 0;
          padding: 10px 15px;
        }

        a:hover {
          background-color: #ffffff50;
          color: $white;
        }
      }
    }

    &__item {
      &--more {
        ul {
          position: relative;
          display: flex;
          top: 0px;
          background-color: transparent;
          box-shadow: none;
          border: none;
          overflow-y: auto;
          li {
            color: $lightgray;
            a {
              padding: 10px 25px;
              background-color: #ffffff10;
            }
          }
        }

        &:hover {
          & > a {
            color: $white;
            background-color: #ffffff30;
          }
        }
      }
      &--more:hover {
        ul {
          //animation: fade_in .3s ease-in-out forwards;
          animation: none;
          display: flex;
          border: none;
          padding: 0;
        }

        a:hover {
          ul {
            display: flex;
          }
        }

        & > a {
          color: $lightgray;
        }
      }
      &--active {
        a {
          background-color: #ffffff50 !important;
        }
      }
    }
  }

  // auth layout
  .auth {
    &__item {
      width: 100%;

      &--bg {
        display: none;
      }
    }
  }

  @keyframes fade_in {
    0% {
      visibility: visible;
      top: 20px;
    }
    100% {
      visibility: visible;
      top: 0;
    }
  }
  @keyframes slide-in {
    0% {
      visibility: visible;
      width: 0;
    }
    100% {
      visibility: visible;
      width: 100vw;
    }
  }
  .container__root {
    gap: 20px;
  }

}


